import React from 'react';

interface PageHeader extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
}

export const PageHeader = ({ subtitle, title }) => {
  return (
    <header className="section">
      <h1 className="title">{title}</h1>
      <p className="subtitle">{subtitle}</p>
    </header>
  );
};
