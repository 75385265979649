import React from 'react';
import PreviewCompatibleImage, { ImageInfo } from '../PreviewCompatibleImage';

interface HeroProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  subheading: string;
  image: ImageInfo;
}

export const Hero: React.FC<HeroProps> = ({ title, subheading, image, ...props }) => {
  return (
    <header className="md:flex items-center" {...props}>
      <div className="md:w-1/2 flex flex-col justify-center section">
        <h1 className="title">{title}</h1>
        <p className="subtitle">{subheading}</p>
      </div>
      <div className="md:flex-1 ">
        <PreviewCompatibleImage imageInfo={image} />
      </div>
    </header>
  );
};
