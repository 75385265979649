import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import { ShopifyProductEdge, ShopifyProduct } from '../../graphql-types';
import { formatCurrency } from '../helpers';
import { PageHeader } from '../components/design';
import { useShopifyService } from '../shopifyService';

interface ProductListingItemProps extends React.HTMLAttributes<HTMLDivElement> {
  product: ShopifyProduct;
}

const ProductListingItem: React.FC<ProductListingItemProps> = ({ product, ...props }) => {
  // TODO Implement improved loading state here - defaulting to static data
  // const isSoleProductVariant =
  //   serviceProduct &&
  //   serviceProduct.variants.edges.length === 1 &&
  //   serviceProduct.variants.edges[0].node.title === 'Default Title';

  return (
    <Link to={`/products/${product.handle}`} key={product.id}>
      <article
        className="flex flex-col w-full h-full bg-white shadow-lg hover:shadow-xl transition ease-in-out duration-700"
        {...props}
      >
        <PreviewCompatibleImage imageInfo={product.images[0].localFile} />
        <article className="flex-1 flex flex-col justify-between items-start section-xs">
          <p className="h5 leading-tight mb-6 ">{product.title}</p>
          <p className="text-gray-700 mt-auto">
            {formatCurrency({
              price: product.variants[0].price,
            })}
          </p>
        </article>
      </article>
    </Link>
  );
};

interface ProductListingProps {
  data: {
    allShopifyProduct: { edges: ShopifyProductEdge[] };
  };
}

const ProductListing: React.FC<ProductListingProps> = ({ data: { allShopifyProduct } }) => {
  const { edges } = allShopifyProduct;

  const { products, collections, loading } = useShopifyService();

  console.log({ products, collections, loading });

  return (
    <Layout>
      <PageHeader
        title="Products"
        subtitle="Varius quam quisque id diam. Ipsum dolor sit amet consectetur adipiscing elit pellentesque
      habitant."
      />
      <section className="section bg-gray-100 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:grid-cols-4 grid-flow-row col-gap-16 row-gap-12">
        {edges.map((edge) => {
          const { node: staticProduct } = edge;
          return <ProductListingItem key={staticProduct.id} product={staticProduct} />;
        })}
      </section>
    </Layout>
  );
};

export default ProductListing;

export const productPageQuery = graphql`
  query ProductListing {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          title
          shopifyId
          description
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          productType
          id
          handle
          images {
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 910, maxHeight: 910) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          variants {
            id
            price
            priceV2 {
              amount
              currencyCode
            }
            shopifyId
            title
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyCollection {
      edges {
        node {
          id
        }
      }
    }
  }
`;
